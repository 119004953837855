/*----------------------------------------*\
  NAVIGATION
  A helper to bind next/previous interactions
\*----------------------------------------*/

import Hammer from 'hammerjs';

export default class Navigation {
  /**
   * @param  {String} el  A selector for an element which contains the navigation
   */
  constructor(el) {
    this.el = el;
    this.node = document.querySelector(this.el);

    this.addNavigationListeners();
  }

  /**
   * Listen for clicks on previous/next buttons
   * Listen for left/right swipe on the container
   */
  addNavigationListeners() {
    this.nextBtnNode = this.node.querySelector('[data-navigate="next"]');
    this.previousBtnNode = this.node.querySelector(
      '[data-navigate="previous"]'
    );

    if (this.previousBtnNode && this.nextBtnNode) {
      this.nextBtnNode.addEventListener('click', this.next.bind(this));
      this.previousBtnNode.addEventListener('click', this.previous.bind(this));
    }

    this.touchMananger = new Hammer(this.node);
    this.touchMananger.on('swiperight', this.previous.bind(this));
    this.touchMananger.on('swipeleft', this.next.bind(this));
  }

  removeNavigationListeners() {
    if (this.previousBtnNode && this.nextBtnNode) {
      this.nextBtnNode.removeEventListener('click', this.next.bind(this));
      this.previousBtnNode.removeEventListener(
        'click',
        this.previous.bind(this)
      );
    }
    this.touchMananger.destroy();
  }
}
