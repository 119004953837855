import { scrollTop } from '@/helpers/utils';
import { restrictScroll } from '@/helpers/scroll';

import Collapse from '@/ui/Collapse';

export default class Header {
  constructor(node) {
    this._node = node;
    this._nodeHeight = this._node.getBoundingClientRect().height;
    this._menuNode = node.querySelector('.menu');
    this._dropdowns = [...this._menuNode.querySelectorAll('.collapse')];
    this._languageChooser = node.querySelector('#language-chooser');
    this._toggleMenuButtons = [
      ...document.querySelectorAll('.toggle-menu-btn'),
    ];
    this._previousScroll = 0;

    restrictScroll(this._menuNode);
    new Collapse(this._languageChooser);

    this._handleDropdownClick = this._handleDropdownClick.bind(this);

    this._addEventListeners();
  }

  _addEventListeners() {
    window.addEventListener('scroll', this._handleScroll.bind(this));

    /**
     * Bind navigation toggle on small screens
     */
    this._toggleMenuButtons.forEach((button) => {
      button.addEventListener('click', () => {
        this._menuNode.classList.toggle('active');
      });
    });

    /**
     * Bind all the navigation dropdowns
     */
    this._dropdowns.forEach((dropdown) => {
      const self = this;
      let listener;

      new Collapse(dropdown, {
        onShow(collapseNode) {
          window.setTimeout(() => {
            listener = self._handleDropdownClick.bind(self, this, collapseNode);
            document.addEventListener('click', listener);
          }, 0);
        },
        onHide() {
          document.removeEventListener('click', listener);
        },
      });
    });
  }

  _handleDropdownClick(collapse, collapseNode, e) {
    const closest = e.target.closest('.collapse');

    if (!closest || closest !== collapseNode) {
      collapse.hide();
    }
  }

  _handleScroll(e) {
    requestAnimationFrame(this._animate.bind(this, e));
  }

  _animate(e) {
    if (window.ENP.skipShowingHeader) {
      window.ENP.skipShowingHeader = false;
      return;
    }

    const top = scrollTop(e.target);
    const diff = Math.abs(this._previousScroll - top);
    const direction = top > this._previousScroll ? 'down' : 'up';

    if (top > this._nodeHeight) {
      this._node.classList.add('is-sticky');
    } else if (top === 0) {
      this._node.classList.remove('is-sticky');
    }

    if (direction === 'down') {
      this._node.classList.remove('active');
    } else if (diff > 100) {
      this._node.classList.add('active');
    } else if (this._previousDirection === direction) {
      return;
    }

    this._previousScroll = top;
    this._previousDirection = direction;
  }
}
