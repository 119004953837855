import 'vite/modulepreload-polyfill';

import '@/ui';
import Slider from '@/ui/Slider';

import '../scss/main.scss';

window.ENP = window.ENP || {};

Object.assign(window.ENP, {
  Slider,
});
