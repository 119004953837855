/*----------------------------------------*\
  SLIDER
  Stack multiple slides to be shown one by
  one in a continuous loop.
\*----------------------------------------*/

import Navigation from './Navigation';
import { Timer } from '@/helpers/utils';

export default class Slider extends Navigation {
  /**
   * @param  {String} el  A selector for a .slider element
   */
  constructor(el) {
    super(el);

    this.ride = (this.node.dataset && this.node.dataset.ride) || false;
    this.defaultDuration =
      (this.node.dataset && parseInt(this.node.dataset.duration, 10)) || 4;
    this.slides = this.node.querySelectorAll('.slider__item');
    this.currentSlide = this.node.querySelector('.slider__item.active');
    this.currentIndex = Array.prototype.indexOf.call(
      this.slides,
      this.currentSlide
    );

    this._addButtonsListeners();

    this._updateButtons();
    this.startTimer();
  }

  /**
   * Listen for clicks on standalone buttons targeting specific slides
   */
  _addButtonsListeners() {
    const buttonNodes = [
      ...document.querySelectorAll(`[data-target="${this.el}"]`),
    ];

    buttonNodes.forEach((node, i) => {
      this._addNavigateListener(node, i);
    });
  }

  _addNavigateListener(node, i) {
    node.addEventListener('click', (e) => {
      e.preventDefault();
      this.navigate(i, e);
    });
  }

  _setProgressAnimationState(state) {
    const progressNodes = [
      ...document.querySelectorAll(
        `[data-target="${this.el}"][data-index="${this.currentIndex}"] .slider-legend__progress`
      ),
    ];

    progressNodes.forEach((progressNode) => {
      progressNode.style.webKitAnimationPlayState =
        progressNode.style.oAnimationPlayState =
        progressNode.style.animationPlayState =
          state;
    });
  }

  /**
   * Activate buttons targeting the slide of the given index
   * Flag buttons targetting previous slides as "seen"
   * Deactivate all other buttons
   */
  _updateButtons(index = this.currentIndex) {
    const buttonNodes = [
      ...document.querySelectorAll(`[data-target="${this.el}"]`),
    ];

    buttonNodes.forEach((node, i) => {
      const hasBeenSeen = node.classList.contains('seen');

      node.classList.remove('active');

      if (i < index) {
        node.classList.add('seen');
      } else if (i === index) {
        // In case we go from seen to active, we need to clone
        // the DOM node to make the animation fire again
        if (hasBeenSeen) {
          const clone = node.cloneNode(true);
          clone.classList.remove('seen');
          node.parentNode.replaceChild(clone, node);
          clone.classList.add('active');
          this._addNavigateListener(clone, i);
        } else {
          node.classList.add('active');
        }
      } else {
        node.classList.remove('seen');
      }
    });
  }

  /*----------------------------------------*\
    PUBLIC
  \*----------------------------------------*/

  /**
   * Go to the next slide
   */
  next(e) {
    const newIndex = this.currentIndex + 1;

    if (newIndex >= this.slides.length) {
      this.navigate(0, e);
    } else {
      this.navigate(newIndex, e);
    }
  }

  /**
   * Go to the previous slide
   */
  previous(e) {
    const newIndex = this.currentIndex - 1;

    if (newIndex < 0) {
      this.navigate(this.slides.length - 1, e);
    } else {
      this.navigate(newIndex, e);
    }
  }

  /**
   * Go to the slide at the given index
   * @param  {Integer} index  The index of the slide you want to show
   */
  navigate(index, e) {
    const newSlide = this.slides[index];

    if (!newSlide || this.transitionTimeout || this.currentSlide === newSlide) {
      return;
    }

    // Cancel any on going timer
    if (this.progressTimeout) {
      this.progressTimeout.pause();
      this.progressTimeout = null;
    }

    // When user interacts manually, we stop riding
    if (e) {
      this.ride = false;
    }

    this._updateButtons(index);

    this.currentSlide.classList.add('leaving');
    newSlide.classList.add('active');

    // Wait for the CSS transition to complete
    this.transitionTimeout = setTimeout(() => {
      this.currentSlide.classList.remove('active');
      this.currentSlide.classList.remove('leaving');
      this.currentIndex = index;
      this.currentSlide = newSlide;
      this.startTimer();
      this.transitionTimeout = null;
    }, 550);
  }

  /**
   * Only if the slider ride automatically
   * Set a timer to go to the next slide once the current is complete
   */
  startTimer() {
    if (!this.ride) {
      return;
    }

    const duration =
      ((this.currentSlide.dataset &&
        parseInt(this.currentSlide.dataset.duration, 10)) ||
        this.defaultDuration) * 1000;

    this._setProgressAnimationState('running');

    if (this.progressTimeout) {
      this.progressTimeout.resume();
    } else {
      this.progressTimeout = new Timer(() => {
        this.next();
      }, duration);
    }
  }

  /**
   * Suspend slider riding
   * Pause progress inside buttons
   */
  pauseTimer() {
    if (this.progressTimeout) {
      this.progressTimeout.pause();
      this._setProgressAnimationState('paused');
    }
  }
}
